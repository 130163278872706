export const navLinks = [
  {
    label: 'Projets',
    pathname: '/articles',
    hash: '',
  },
  {
    label: 'A propos',
    pathname: '/about',
    hash: '',
  },
  {
    label: 'Contact',
    pathname: '/contact',
  },
];

export const socialLinks = [
  {
    label: 'Twitter',
    url: 'https://twitter.com/NathanGtle',
    icon: 'twitter',
  },
  // {
  //   label: 'Dribbble',
  //   url: 'https://dribbble.codyb.co',
  //   icon: 'dribbble',
  // },
  // {
  //   label: 'Github',
  //   url: 'https://github.codyb.co',
  //   icon: 'github',
  // },
];
